import { DISTRICT_TABS } from '@domain/districts';

import { useFetchSiteCoreReport } from '@repositories/reports';

import {
  FEATURE_INVOICE,
  FEATURE_LEADS_MML,
  FEATURE_SHAREPOINT,
  isEnabled,
} from '@utils/features';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';
import { useDistrictFinanceMenu } from '@hooks/useDistrictFinanceMenu';

import { SitecoreReportTypeEnum } from '@typings/sitecore';

const isMembershipCandidatesFeature = isEnabled(FEATURE_LEADS_MML);

export const useTabsPermissions = (
  {
    dashboardsAccess: hasPermissionToDashboard,
    conferencesAccess: hasPermissionToConference,
    leadsAccess: hasPermissionToLeads,
  }: { [key: string]: Boolean },
  districtId: string,
  isAuthenticatedToFinance?: boolean
) => {
  const { t, i18n } = useTranslation();

  const drupalReports = useMenu(
    'menu-district-admin-reports',
    i18n.language,
    true,
    false
  );
  const sitecoreReports = useFetchSiteCoreReport(
    SitecoreReportTypeEnum.District
  );

  const {
    data: financeData,
    error: financeError,
    loading: financeLoading,
  } = useMenu('menu-district-admin-finances', i18n.language, true, false);

  const isInvoiceFlagEnabled = isEnabled(FEATURE_INVOICE);
  const { data: newFinanceData } = useDistrictFinanceMenu(
    t,
    districtId,
    isAuthenticatedToFinance
  );

  // Conditionally determine which data to use based on the feature flag
  const reportsData = isEnabled(FEATURE_SHAREPOINT)
    ? sitecoreReports?.data?.reportData ?? []
    : drupalReports?.data;
  const reportsError = isEnabled(FEATURE_SHAREPOINT)
    ? sitecoreReports.error
    : drupalReports.error;
  const reportsLoading = isEnabled(FEATURE_SHAREPOINT)
    ? sitecoreReports.loading
    : drupalReports.loading;

  const hasPermissionToReports = reportsData?.length > 0 && !reportsError;
  const hasPermissionToFinance =
    (financeData?.length > 0 && !financeError) || newFinanceData?.length > 0;

  // use nowTerm as it is defined only once and is constant
  // Dashboard tab should be visible only for DO for the current year
  const allowedTabs = [
    {
      hasPermissions: hasPermissionToReports,
      name: DISTRICT_TABS.REPORTS,
    },
    {
      hasPermissions: hasPermissionToFinance,
      name: DISTRICT_TABS.FINANCE,
    },
    {
      hasPermissions: hasPermissionToConference,
      name: DISTRICT_TABS.CONFERENCES,
    },
    {
      hasPermissions: hasPermissionToDashboard,
      name: DISTRICT_TABS.DASHBOARD,
    },
    {
      hasPermissions: hasPermissionToLeads && isMembershipCandidatesFeature,
      name: DISTRICT_TABS.MEMBERSHIPCANDIDATE,
    },
  ]
    .filter(el => !el.hasPermissions)
    .map(el => el.name);

  return {
    reportsData,
    financeData: isInvoiceFlagEnabled ? newFinanceData : financeData,
    isPermissionsLoading: reportsLoading || financeLoading,
    hasPermissionToReports,
    hasPermissionToFinance,
    hasPermissionToConference,
    hasPermissionToDashboard,
    hasPermissionToLeads,
    allowedTabs,
  };
};
