import { getDestinationPath } from '@utils/getDestinationPath';
import getDonateWidgetMode from '@utils/getDonateWidgetMode';
import {
  getLangFromPath,
  isLocalizedPath,
  localizedPath,
} from '@utils/localized-path';

import { WIDGET_DESTINATION_PATHS } from '@constants/index';

import { LoadReactApp } from '@typings/widget';

/**
 * Here below is all the logic covers Donate Widget integration onto page
 *
 * To be called in 'gatsby-browser.js'
 */
export const waitForDonateWidget = (
  selector: string,
  checkFrequencyInMs: number,
  timeoutInMs: number
) => {
  const { destination } = getDestinationPath(WIDGET_DESTINATION_PATHS);

  const existedScript: HTMLScriptElement | null = document.querySelector(
    'script[src*="loadData.js"]'
  );

  const doanteWidgetURL = process.env.GATSBY_DONATE_WIDGET_URL as string;
  const donateWidgetLoadBuildJS = process.env
    .GATSBY_DONATE_WIDGET_LOAD_BUILD_JS as string;

  const script: HTMLScriptElement =
    existedScript || document.createElement('script');

  if (!existedScript) {
    script.src = donateWidgetLoadBuildJS;
    script.type = 'text/javascript';
    document.head.prepend(script);
  }

  const interval = setInterval(() => {
    const element = document.querySelector(selector);
    const elementContainer = document.getElementById('donate-widget');

    const user = window.__gatsby_user;
    const gatsbyLanguage = window.__gatsby_language;

    const { pathname, origin } = window.location;

    const donateWidgetObject =
      getDonateWidgetMode(destination) === 'invoice'
        ? {
            invoice: JSON.parse(
              window.localStorage.getItem('serverValue') as string
            ),
            getLanguage: gatsbyLanguage,
            mode: getDonateWidgetMode(destination),
          }
        : {
            getLanguage: gatsbyLanguage,
            mode: getDonateWidgetMode(destination),
          };

    const donateWidget = () =>
      (window.loadReactApp as LoadReactApp)(
        doanteWidgetURL,
        donateWidgetObject,
        document.getElementById('donate-widget')
      );

    // CASE 1
    // Breaks interval loop if donateWidget and element are loaded on the page.
    // This means no other activities is needed.
    if (window.loadReactApp && element !== null) {
      clearInterval(interval);
    }
    // CASE 2
    // Makes localized navigation or fires widget with params
    else if (
      window.loadReactApp &&
      element === null &&
      elementContainer !== null
    ) {
      // CASE 2.1
      // If current path is not localized then add
      // localization and navigate to localized path.
      if (
        !isLocalizedPath(pathname) &&
        gatsbyLanguage !== getLangFromPath(pathname)
      ) {
        clearInterval(interval);
        window.location.href =
          origin + localizedPath(pathname, getLangFromPath(pathname) || '');
      }
      // CASE 2.2
      // Make delayed donateWidget call
      else if (
        (user &&
          isLocalizedPath(pathname) &&
          gatsbyLanguage === getLangFromPath(pathname)) ||
        (!user && gatsbyLanguage === getLangFromPath(pathname))
      ) {
        clearInterval(interval);
        setTimeout(() => donateWidget(), 2000);
      }
    }
    // CASE 3
    // Breaks interval loop after timeout
    else if (
      (user && gatsbyLanguage !== getLangFromPath(pathname)) ||
      (!user && gatsbyLanguage === getLangFromPath(pathname))
    ) {
      // CASE 3.1
      // If current path doesn't match gatsbyLanguage and user is logged in then
      // make full page reload using language from path for localization
      setTimeout(() => clearInterval(interval), timeoutInMs);
      window.location.href =
        origin + localizedPath(pathname, getLangFromPath(pathname) || '');
    }
    // CASE 3.2
    // Otherwise no additional checks are needed.
    // We can clear interval as og widget was not found during timeoutInMs.
    else setTimeout(() => clearInterval(interval), timeoutInMs);
  }, checkFrequencyInMs);
};
