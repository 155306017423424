import axios from 'axios';

import { log } from '@hooks/logger';

import { DIS } from '@typings/dis';
import { LogLevel } from '@typings/operations';

export const getDailyDistrictBalanceReport = async (districtId: string) => {
  const graphqlQuery = `
    query fetchDistrictBalanceReport($orgType: OrgType!, $orgId: String!) {
      clubBalanceReport: getClubBalanceReport(orgType: $orgType, orgId: $orgId) {
        fileContent
        riDistrictId
      }
    }
  `;

  const fetchDistrictFinanceAccessQuery = `
  query fetchDistrictFinanceAccess($districtId: String!) {
    districtFinanceOperationAccess(districtId: $districtId)
  }
  `;

  const graphqlEndpoint = `${process.env.GATSBY_MRX_API_BUILD_URL}/graphql`;

  const channel = new BroadcastChannel('district-balance-reports');
  const triggerErrorNotification = () => {
    setTimeout(() => {
      channel.postMessage({ type: 'error' });
    }, 5000);
  };

  try {
    const pdfVariables = {
      orgType: DIS.OrganizationTypeEnum.District,
      orgId: districtId,
    };

    try {
      const pdfResponse = await axios.post(
        graphqlEndpoint,
        {
          query: graphqlQuery,
          variables: pdfVariables,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      const pdfData = pdfResponse.data.data.clubBalanceReport;

      let hasFinanceAccess = false;

      if (pdfData.riDistrictId) {
        try {
          const districtResponse = await axios.post(
            graphqlEndpoint,
            {
              query: fetchDistrictFinanceAccessQuery,
              variables: { districtId: pdfData.riDistrictId },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            }
          );

          hasFinanceAccess =
            districtResponse?.data?.data?.districtFinanceOperationAccess ??
            false;
        } catch (error) {
          triggerErrorNotification();
          if (axios.isAxiosError(error)) {
            log({
              level: LogLevel.Debug,
              message: `Gatsby: fetchDistrictFinanceAccess, 'GraphQL Request Failed:', ${error.message}`,
            });
          } else {
            log({
              level: LogLevel.Debug,
              message: `Gatsby: fetchDistrictFinanceAccess, 'Unknown error occurred:', ${error}`,
            });
          }
        }
      }

      if (
        pdfData &&
        typeof pdfData === 'object' &&
        pdfData.fileContent &&
        hasFinanceAccess
      ) {
        const pdfWindow = window.open('', '_blank');
        if (pdfWindow) {
          pdfWindow.document.write(
            `<iframe width="100%" height="100%" src="data:application/pdf;base64,${encodeURIComponent(
              pdfData?.fileContent ?? ''
            )}"></iframe>`
          );
        }
      } else if (hasFinanceAccess) {
        triggerErrorNotification();
      }
    } catch (error) {
      triggerErrorNotification();
      if (axios.isAxiosError(error)) {
        log({
          level: LogLevel.Debug,
          message: `Gatsby: districtBalanceReport, 'GraphQL Request Failed:', ${error.message}`,
        });
      } else {
        log({
          level: LogLevel.Debug,
          message: `Gatsby: districtBalanceReport, 'Unknown error occurred:', ${error}`,
        });
      }
    }
  } catch (error) {
    triggerErrorNotification();
    if (axios.isAxiosError(error)) {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: fetchDistrictBalanceReport, 'GraphQL Request Failed:', ${error.message}`,
      });
    } else {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: fetchDistrictBalanceReport, 'Unknown error occurred:', ${error}`,
      });
    }
  }
};
