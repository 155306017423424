// Utils
import { getIntlDisplayName } from '@utils/getIntlDisplayName';

// Types
import { AddressInput, MeetingInput } from '@typings/operations';
import { ClubMeetingValues } from '../types';

// Constants
import { DISPLAY_TYPE } from '@domain/common';

export default (
  currentLanguage: string,
  {
    weekday,
    type,
    languageId,
    countryId,
    city,
    postalCode,
    lineOne,
    lineTwo,
    lineThree,
    internationalProvince,
    stateId,
    ...values
  }: ClubMeetingValues
): MeetingInput => ({
  ...values,
  weekday,
  type: type!,
  language: getIntlDisplayName(
    currentLanguage,
    DISPLAY_TYPE.LANGUAGE,
    languageId!
  ),
  languageId,
  address: {
    countryId,
    city,
    postalCode,
    lineOne: lineOne || '', // required in input but not query result
    lineTwo,
    lineThree,
    stateId,
    // submitting the international province gives a 500 error on DIS?
    internationalProvince,
  } as AddressInput,
});
