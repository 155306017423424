import { useLazyQuery } from '@apollo/client';

import { searchAllActiveClubsInDistrictQuery } from '../queries/fetchAllActiveClubsInDistrict';

import {
  SearchAllActiveClubsInDistrictQuery,
  SearchAllActiveClubsInDistrictQueryVariables,
} from '@typings/operations';

export const useSearchAllActiveClubsByDistrictId = () =>
  useLazyQuery<
    SearchAllActiveClubsInDistrictQuery,
    SearchAllActiveClubsInDistrictQueryVariables
  >(searchAllActiveClubsInDistrictQuery);
