import { gql } from '@apollo/client';

export const fetchClubSelectOptions = gql`
  query ClubSelectOptions(
    $clubName: String
    $riDistrictId: Int
    $riClubId: String
    $clubType: ClubTypeFilterValue
    $page: Int = 1
    $pageSize: Int = 100
    $advanced: Boolean = true
    $isSearchByClubName: Boolean = true
  ) {
    clubs: searchClub(
      clubNamePartial: $clubName
      riDistrictId: $riDistrictId
      riClubId: $riClubId
      clubType: $clubType
      page: $page
      pageSize: $pageSize
      advanced: $advanced
      isSearchByClubName: $isSearchByClubName
    ) {
      results {
        id
        fullName
        type
        riClubId
        status
      }
    }
  }
`;
