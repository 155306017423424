import axios from 'axios';

import { RIBI_COUNTRY_LIST } from '@domain/common';

import { log } from '@hooks/logger';

import { DIS } from '@typings/dis';
import { LogLevel } from '@typings/operations';

export const getDailyClubBalanceReport = async (clubId: string) => {
  const fetchClubInvoiceDataQuery = `
  query fetchClubInvoiceData($clubId: String!) {
    club: clubById(clubId: $clubId) {
      clubType
      riClubId
      financeOperationAccess {
        hasAccess
      }
      physicalLocation {
        country
      }
    }
  }
  `;

  const graphqlQuery = `
    query fetchClubBalanceReport($orgType: OrgType!, $orgId: String!) {
      clubBalanceReport: getClubBalanceReport(orgType: $orgType, orgId: $orgId) {
        fileContent
      }
    }
  `;

  const graphqlEndpoint = `${process.env.GATSBY_MRX_API_BUILD_URL}/graphql`;

  const channel = new BroadcastChannel('club-balance-reports');
  const triggerErrorNotification = () => {
    setTimeout(() => {
      channel.postMessage({ type: 'error' });
    }, 5000);
  };

  try {
    // Fetch club invoice data
    const response = await axios.post(
      graphqlEndpoint,
      {
        query: fetchClubInvoiceDataQuery,
        variables: { clubId },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    const data = response.data.data.club;
    const hasClubFinanceAccess = data.financeOperationAccess?.hasAccess;
    const clubCountry = data?.physicalLocation?.country || '';
    const isRibiCountry =
      RIBI_COUNTRY_LIST.includes(clubCountry) &&
      data.clubType === DIS.ClubTypeEnum.RotaryClub;

    if (hasClubFinanceAccess && !isRibiCountry) {
      const pdfVariables = {
        orgType: data.clubType,
        orgId: data.riClubId,
      };

      try {
        const pdfResponse = await axios.post(
          graphqlEndpoint,
          {
            query: graphqlQuery,
            variables: pdfVariables,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        );

        const pdfData = pdfResponse.data.data.clubBalanceReport;

        if (pdfData && typeof pdfData === 'object' && pdfData.fileContent) {
          const pdfWindow = window.open('', '_blank');
          if (pdfWindow) {
            pdfWindow.document.write(
              `<iframe width="100%" height="100%" src="data:application/pdf;base64,${encodeURIComponent(
                pdfData.fileContent ?? ''
              )}"></iframe>`
            );
          }
        } else {
          triggerErrorNotification();
        }
      } catch (error) {
        triggerErrorNotification();
        if (axios.isAxiosError(error)) {
          log({
            level: LogLevel.Debug,
            message: `Gatsby: clubBalanceReport, 'GraphQL Request Failed:', ${error.message}`,
          });
        } else {
          log({
            level: LogLevel.Debug,
            message: `Gatsby: clubBalanceReport, 'Unknown error occurred:', ${error}`,
          });
        }
      }
    }
  } catch (error) {
    triggerErrorNotification();
    if (axios.isAxiosError(error)) {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: fetchClubInvoiceData, 'GraphQL Request Failed:', ${error.message}`,
      });
    } else {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: fetchClubInvoiceData, 'Unknown error occurred:', ${error}`,
      });
    }
  }
};
