import { gql } from '@apollo/client';

export const searchAllActiveClubsInDistrictQuery = gql`
  query searchAllActiveClubsInDistrict(
    $id: String
    $clubName: String
    $clubTypes: [String]
    $status: [String]
    $riClubId: String!
    $riDistrictId: Int!
    $page: Int
    $pageSize: Int
  ) {
    allActiveClubsInDistrict: searchAllActiveClubsInDistrict(
      id: $id
      clubName: $clubName
      clubTypes: $clubTypes
      status: $status
      riClubId: $riClubId
      riDistrictId: $riDistrictId
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      clubs {
        id
        riClubId
        name
        type
        location {
          country
          state
          countryId
          city
          stateId
        }
      }
    }
  }
`;
