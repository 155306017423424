import { useLazyQuery } from '@apollo/client';

import { fetchDistrictFinanceAccessQuery } from '../queries';

import {
  FetchDistrictFinanceAccessQuery,
  FetchDistrictFinanceAccessQueryVariables,
} from '@typings/operations';

export const useFetchDistrictFinanceAccess = () => {
  return useLazyQuery<
    FetchDistrictFinanceAccessQuery,
    FetchDistrictFinanceAccessQueryVariables
  >(fetchDistrictFinanceAccessQuery);
};
