import { gql } from '@apollo/client';

export const fetchClubInvoiceDataQuery = gql`
  query fetchClubInvoiceData($clubId: String!) {
    club: clubById(clubId: $clubId) {
      clubName
      clubType
      riClubId
      district
      financeOperationAccess {
        hasAccess
      }
      physicalLocation {
        country
        countryId
      }
    }
  }
`;
