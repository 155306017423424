import { gql } from '@apollo/client';

export const fetchAdminAccessRightsQuery = gql`
  query fetchAdminAccessRights($clubId: String!) {
    club: clubById(clubId: $clubId) {
      clubName
      clubType
      organizationBase
      operationsAccess {
        memberships
        financesGoalsReports
        clubId
        leaderships
      }
      financeOperationAccess {
        hasAccess
      }
      riClubId
    }
  }
`;
