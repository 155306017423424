export const getOfficersPagePath = (clubId?: string) =>
  `/club/${clubId}/officers`;

export const getAddMemberPagePath = (clubId?: string) =>
  `/club/${clubId}/add-member`;

export const getClubDetailsPath = (clubId?: string | null) =>
  `/club/${clubId}/details`;

export const getClubMembersPath = (clubId?: string) => `/club/${clubId}/roster`;

export const getEditMembershipPath = (clubId: string, membershipId: string) =>
  `/club/${clubId}/edit-membership/${membershipId}`;

export const getDelegationRolesPath = () => `/delegation/roles`;

export const getClubFinancePath = (clubId: string) =>
  `/club/${clubId}/finances`;

export const getClubInvoicePath = (clubId: string) => `/club-invoice/${clubId}`;

export const getReportsPath = () => `/membership-and-foundation-reports`;
